import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { AdminPage } from './routes/admin'

const Reader = lazy(() => import('./routes/Reader'))
const Admin = lazy(() => import('./routes/admin/Admin'))

const App: React.FC = () => {

  return (
    <div className="App">

      <Router>
        <Suspense fallback={<div></div>}>
          <Routes>
            <Route path="/:id" element={<Reader />} />
            <Route path="/" element={<Reader />} />

            {/* { !!isAdmin && */}
            <React.Fragment>
              <Route path="/admin" element={<Admin page={AdminPage.Landing} />} />
              <Route path="/admin/books/new" element={<Admin page={AdminPage.BookForm}  />} />
							<Route path="/admin/books/edit/:id" element={<Admin page={AdminPage.BookForm} />} />
            </React.Fragment>
            {/* } */}
          </Routes>
        </Suspense>
      </Router>
    </div>
  )
}
export default App
